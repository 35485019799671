import { Component } from 'react';
import Router from 'next/router';
import nextCookie from 'next-cookies';
import cookie from 'js-cookie';
import moment from 'moment';
import { logEvent } from './analytics';
import { getUrl } from './api.js';

export const login = async (token, currentAPI, reload = false) => {
  cookie.set('token', token.access_token, { expires: 365 });
  cookie.set('reach_token', token.reach_access_token, { expires: 365 });
  const reachAccounts = window.localStorage.getItem('reachAccounts');
  // This If Statement Ensure That when user logged in from login page, set "reachAccounts" Cookie
  if (!reachAccounts) {
    const profile = await getUrl(`${currentAPI}/profile`, 'reach_token');
    const accountList = [
      {
        token: token.access_token,
        reach_token: token.reach_access_token,
        profile: profile.data,
        isActive: true,
      },
    ];
    window.localStorage.setItem('reachAccounts', JSON.stringify(accountList));
  } else {
    // This Statement Runs when already logged in, and has two cases
    // 1. user clicked on "Add Another Account"
    // 2. User Switched Account
    const profile = await getUrl(`${currentAPI}/profile`, 'reach_token');
    const accountList = JSON.parse(
      window.localStorage.getItem('reachAccounts')
    );
    const setActiveFalse = accountList.map(acc => ({
      ...acc,
      isActive: false,
    }));

    const selectedAccountIndex = setActiveFalse.findIndex(
      acc => acc.profile.username === token.username
    );

    if (selectedAccountIndex >= 0) {
      setActiveFalse[selectedAccountIndex].isActive = true;
      window.localStorage.setItem(
        'reachAccounts',
        JSON.stringify(setActiveFalse)
      );
    } else {
      const withNewAcc = [
        ...setActiveFalse,
        {
          token: token.access_token,
          reach_token: token.reach_access_token,
          profile: profile.data,
          isActive: true,
        },
      ];
      window.localStorage.setItem('reachAccounts', JSON.stringify(withNewAcc));
    }
  }
  logEvent('login', `${token.username}-time(${moment(new Date())})`);
  window.localStorage.setItem('login_time', moment(new Date()));
  window.localStorage.setItem('username', token.username);
  Router.push('/');
  if (reload) {
    window.location.reload();
    logEvent('login', `login`);
  }
};

export const logout = (
  userUsername,
  currentAPI_Reach,
  addNewAccount = false
) => {
  var diffInDays, diffInMin;
  if (!window.localStorage.getItem('login_time')) {
    window.localStorage.setItem('login_time', moment(new Date()));
  }
  diffInDays =
    moment().diff(window.localStorage.getItem('login_time'), 'days') + 'd';
  diffInMin =
    moment().diff(window.localStorage.getItem('login_time'), 'minutes') + 'm';

  window.localStorage.setItem('logout', Date.now());

  logEvent('session', `${userUsername}-duration(${diffInDays}h:${diffInMin}m)`);

  logEvent('logout', `${userUsername}-time(${moment(new Date())})`);

  cookie.remove('token');
  cookie.remove('reach_token');
  cookie.remove('currentID');
  window.localStorage.removeItem('login_time');

  // Handle Multiple Accounts has two cases:
  // 1. User Already logged in more than account, then it should logout only the active one
  // 2. If there is only one account and logout should redirect to /login page and clear all cookies related
  if (!addNewAccount) {
    const accountList =
      JSON.parse(window.localStorage.getItem('reachAccounts')) || [];
    const filteredAccounts = accountList.filter(
      acc => acc.profile.username !== userUsername
    );

    if (filteredAccounts.length) {
      window.localStorage.setItem(
        'reachAccounts',
        JSON.stringify(filteredAccounts)
      );
      const activeAccout = filteredAccounts[0];
      login(
        {
          access_token: activeAccout.token,
          reach_access_token: activeAccout.reach_token,
          username: activeAccout.profile.username,
        },
        currentAPI_Reach,
        true
      );
    } else {
      window.localStorage.removeItem('reachAccounts');
      window.location.href = '/login';
    }
  }
};

// Gets the display name of a JSX component for dev tools
const getDisplayName = Component =>
  Component.displayName || Component.name || 'Component';

export const withAuthSync = WrappedComponent =>
  class extends Component {
    static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`;
    static currentDisplayComponent = getDisplayName(
      WrappedComponent
    ).toLowerCase();

    static async getInitialProps(ctx) {
      const token = auth(ctx);
      const componentProps =
        WrappedComponent.getInitialProps &&
        (await WrappedComponent.getInitialProps(ctx));

      return { ...componentProps, token };
    }

    constructor(props) {
      super(props);

      this.syncLogout = this.syncLogout.bind(this);
    }

    componentDidMount() {
      window.addEventListener('storage', this.syncLogout);
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.syncLogout);
      window.localStorage.removeItem('logout');
    }

    syncLogout(event) {
      if (event.key === 'logout') {
        console.log('logged out from storage!');
        Router.push('/login');
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export const auth = ctx => {
  const { token } = nextCookie(ctx);

  /*
   * This happens on server only, ctx.req is available means it's being
   * rendered on server. If we are on server and token is not available,
   * means user is not logged in.
   */
  if (ctx.req && !token) {
    ctx.res.writeHead(302, { Location: '/login' });
    ctx.res.end();
    return;
  }

  // We already checked for server. This should only happen on client.
  if (!token) {
    Router.push('/login');
  }

  return token;
};

export const getToken = () => {
  // Retrieves the user token from cookies
  return cookie.get('token');
};

export const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken();
  return !!token; // handwaiving here
};
