import axios from 'axios';
import Cookies from 'js-cookie';

import { logout } from './auth.js';

export const postUrl = async (url = '', data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Cookies.get('token'),
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: data, // body data type must match "Content-Type" header
  });

  if (response.status === 500 || response.status === 401) {
    logout();
  }

  return await response;
};

export const getUrl = async (url = '', tokenType = 'token') => {
  // Default options are marked with *
  const token = Cookies.get(tokenType);
  if (token === undefined || token === null || !token.length) {
    return;
  }

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Language': Cookies.get('next-i18next'),
    },
  });

  if (response.ok) {
    const json = response.json();

    if (response.status === 500 || response.status === 401) {
      logout();
      window.localStorage.removeItem('reachAccounts');
      Cookies.remove('token');
      Cookies.remove('reach_token');
      Cookies.remove('currentID');
      window.localStorage.removeItem('login_time');
      window.location.href = '/login';
    }

    return json;
  } else {
    window.localStorage.removeItem('reachAccounts');
    Cookies.remove('token');
    Cookies.remove('reach_token');
    Cookies.remove('currentID');
    window.localStorage.removeItem('login_time');
    window.location.href = '/login';
  }
};

export const getUrlCustom = (url = '', tokenType = 'token') =>
  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Cookies.get(tokenType),
      'Content-Language': Cookies.get('next-i18next'),
    },
  })
    .then(res => res.json())
    .then(res => {
      if (res.status === 500 || res.status === 401) {
        logout();
      }
      return res;
    });

export const delUrl = async (url = '', tokenType = 'token') => {
  // Default options are marked with *
  await axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Cookies.get(tokenType),
    },
  });
};
// ! Used in RecentCampagins.js [Difference is return] cuz I need to handle Errors/Success States
export const delUrlCustom = (url = '', tokenType = 'token') => {
  // Default options are marked with *
  return axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Cookies.get(tokenType),
    },
  });
};

export const putUrl = async (url = '', data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Cookies.get('token'),
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: data, // body data type must match "Content-Type" header
  });

  if (response.status === 500 || response.status === 401) {
    logout();
  }

  return await response;
};

export const customPost = async (url = '', data = {}) =>
  axios(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: 'Bearer ' + Cookies.get('reach_token'),
      'Content-Language': Cookies.get('next-i18next'),
    },
    data: data,
  });

export const influencerPost = async (url = '', data = {}, contentTypeJson) => {
  const ifContentTypeJson = contentTypeJson
    ? {
        'Content-Type': 'application/json',
      }
    : {};

  return axios(url, {
    method: 'POST',
    headers: {
      ...ifContentTypeJson,
      Accept: 'application/json',
      Authorization: 'Bearer ' + Cookies.get('token'),
      'Content-Language': Cookies.get('next-i18next'),
    },
    data: data,
  });
};
