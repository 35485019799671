import React from 'react';
import styled from 'styled-components';

export const RawLoading = ({ className }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    enableBackground="new 0 0 40 40"
    className={className}
  >
    <path
      opacity="0.2"
      fill="#0077c1"
      d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
          s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
          c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
    />
    <path
      fill="#0077c1"
      d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
          C22.32,8.481,24.301,9.057,26.013,10.047z"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 20 20"
        to="360 20 20"
        dur="0.5s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

const Loading = props => {
  const { options, bgLoading } = props;
  return (
    <LoadingBackground bgLoading={bgLoading}>
      <LoadingStyle options={options} className="loading text-center">
        <RawLoading />
      </LoadingStyle>
    </LoadingBackground>
  );
};

const LoadingStyle = styled.div`
  position: ${props => (props.options === false ? 'unset' : 'fixed')};
  top: ${props => (props.options === false ? 'unset' : '50%')};
  left: ${props => (props.options === false ? 'unset' : '50%')};
`;

const LoadingBackground = styled.div`
  background: ${props =>
    props.bgLoading ? 'rgba(255, 255, 255, 0.4)' : 'unset'};
  width: ${props => (props.bgLoading ? '100vw' : 'unset')};
  height: ${props => (props.bgLoading ? '100vh' : 'unset')};
  position: ${props => (props.bgLoading ? 'fixed' : 'unset')};
  left: 0;
  top: 0;
  z-index: 99999;
`;

export default Loading;
